import * as React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Seo from '../components/Seo'

const IndexPage = ({ data }) => {

  return (
    <Layout homePage={true}>
      <Seo
        type="page"
        title={data.site.siteMetadata.siteName}
        description={data.site.siteMetadata.siteDescription}
        path={'/'}
      />



      {/* <div className="flex flex-col items-center h-full justify-items-center content-center items-center"> */}
      {/* transition duration-500 ease-in-out transform hover:skew-y-2 */}
      <div className="font-display bg-light p-5 md:p-10 mt-2 mb-2 rounded-md">
        {/* <button className="bg-green-500">Button</button> */}
        <h1 className="text-6xl pb-4 transition duration-500 ease-in-out transform hover:translate-x-8">Hi, I'm Geoffrey Hunt</h1>
        <h2 className="text-2xl pb-4">I write code for a living. and do some other things.</h2>
        <p className="text-xl pb-2">Check out my <Link className="underline text-bright transition duration-500 ease-in-out transform hover:text-dark hover:scale-110 inline-block hover:bg-light" to="/projects/">projects</Link>, <Link className="underline text-bright transition duration-500 ease-in-out transform hover:text-dark hover:scale-110 inline-block hover:bg-light" to="/blog/">blog</Link> and ways to <Link className="underline text-bright transition duration-500 ease-in-out transform hover:text-dark hover:scale-110 inline-block hover:bg-light" to="/support/">support me</Link>.</p>
        <p className="text-l pb-2">If you need a software developer for contract work <Link className="underline text-bright transition duration-500 ease-in-out transform hover:text-dark hover:scale-110 hover:bg-light inline-block" to="/contact/">send me a message!</Link></p>
      </div>
      {/* </div> */}

    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteDescription
        siteName
      }
    }
    # file(relativePath: { eq: "shoe1.png" }) {
    #   childImageSharp {
    #     # Specify the image processing specifications right in the query.
    #     fixed(width: 200) {
    #       ...GatsbyImageSharpFixed
    #     }
    #   }
    # }
    # allMdx(sort: { order: DESC, fields: frontmatter___title }) {
    #   edges {
    #     node {
    #       frontmatter {
    #         title
    #       }
    #       id
    #       slug
    #       excerpt(truncate: true)
    #       body
    #     }
    #   }
    # }
  }
`;
